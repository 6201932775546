import Style from './Hours.module.scss';
import { scheduleController as controller } from '../../store/data/hours';

export type HoursProps = { center?: boolean; className?: string };

export function Hours(props: HoursProps) {
  const hoursBlockClass = (i: number, style: string) =>
    `${i === 0 ? Style.firstIndex : ''}`;

  const rootClass = `${props.center ? Style.hours : Style.hours} ${
    props.className
  }`;

  return (
    <div className={rootClass}>
      <div className={Style.hoursContainer}>
        {controller.makeSchedule().map((day, i) => (
          <div key={i} className={`${Style.hoursRowContainer}`}>
            <div className={hoursBlockClass(i, 'body1')}>{day.weekday}</div>
            <div className={hoursBlockClass(i, 'body1')}>
              {`${day.hours.start}`}
            </div>
            <div className={hoursBlockClass(i, 'body1')}>
              {`${day.hours.end}`}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
