import Style from './Wings.module.scss';

import { wings } from '../../../store/data/wings';
import { MenuComp } from '../../../components/menu';
import { appetizerSection } from '../../../store/data/dinnerMenuData';

export function Wings() {
  const data: [string, string, string][] = [
    ['Wing Zing', 'Our original wing', 'colorRed'],
    ['Naked Wing', 'The classic', 'colorBrown'],
    ['Breaded Wing', 'Child friendly', 'colorYellow'],
  ];

  return (
    <div className={Style.wings}>
      <div className={Style.content}>
        <div className={Style.wingsContainer}>
          <div
            style={{
              padding: '32px',
            }}>
            <div className='mdc-typography--headline3'>Wings</div>
          </div>

          <div
            style={{ background: 'rgba(255, 255, 255, 0.05)' }}
            className={Style.wingCard}>
            <div className={` ${Style.wingStepHeader}`}>
              <div className={Style.step}>1</div>
              <div className={'mdc-typography--body1'}>
                Pick from one of our three styles!
              </div>
            </div>
            <div className={Style.wingsStyleContainer}>
              {data.map(([t, s, c], i: number) => (
                <div key={i} className={`${Style.wingsItem} ${Style[c]}`}>
                  {/* <div>1</div> */}
                  <div className={Style.content}>
                    <div className={`mdc-typography--headline5`}>{t}</div>
                    <div className={`mdc-typography--subtitle1`}>{s}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div
            style={{ background: 'rgba(255, 255, 255, 0.07)' }}
            className={`${Style.wingCard}`}>
            <div className={` ${Style.wingStepHeader}`}>
              <div className={Style.step}>2</div>
              <div className={`mdc-typography--body1`}>
                Choose a sauce for on top or on the side
              </div>
            </div>
            <div className={Style.wingsSaucesContainer}>
              <div className={`mdc-typography--body2`}>Sweet BBQ</div>
              <div className={`mdc-typography--body2`}>Buffalo</div>
              <div className={`mdc-typography--body2`}>Buffalo Blue Cheese</div>
              <div className={`mdc-typography--body2`}>Fire Station #5</div>
            </div>
          </div>

          <div
            style={{ background: 'rgba(255, 255, 255, 0.05)' }}
            className={Style.wingCard}>
            <div className={` ${Style.wingStepHeader}`}>
              <div className={Style.step}>3</div>
              <div className={`mdc-typography--body1`}>Select a size</div>
            </div>

            <div className={Style.wingPriceContainer}>
              <div className={`${Style.wingsPriceItem}`}>
                <div className={`mdc-typography--headline6`}>6 Wings</div>
                <div className={Style.price}>$6.99</div>
              </div>
              <div className={`${Style.wingsPriceItem}`}>
                <div className={`mdc-typography--headline6`}>12 Wings</div>
                <div className={Style.price}>$13.99</div>
              </div>
              <div className={`${Style.wingsPriceItem}`}>
                <div className={`mdc-typography--headline6`}>24 Wings</div>
                <div className={Style.price}>$24.99</div>
              </div>
            </div>
          </div>

          <MenuComp cols={[[wings]]} />
        </div>

        <MenuComp cols={[[appetizerSection]]} />
      </div>
    </div>
  );
}

export default Wings;
