import { MenuSection } from '../../types/MenuSection';

export const wings: MenuSection = {
  title: 'Gourmet Wings',
  items: [
    {
      title: 'Italian Wings',
      description:
        'Tossed in garlic butter, diced tomatoes, parsley and parmesan cheese',

      priceAlts: [
        { title: '6 wings', price: '$6.99' },
        { title: '12 wings', price: '$13.99' },
        { title: '24 wings', price: '$24.99' },
      ],
    },
    {
      title: 'Mediterranean Wings',
      description:
        'Tossed in white wine butter sauce, tomatoes, black olives, fresh parsley and onion, and topped with feta cheese',
      priceAlts: [
        { title: '6 wings', price: '$6.99' },
        { title: '12 wings', price: '$13.99' },
        { title: '24 wings', price: '$24.99' },
      ],
    },
  ],
};
