import React, { useEffect, useRef } from 'react';
import Style from './Hero.module.scss';

// import backgroundImage from '../../assets/IMG_0150.png';

import { Button } from '../../components/button';
import { Hours } from '../../components/hours';

export interface HeroProps {}

export function Hero() {
  const lineRef = useRef<HTMLDivElement>(null);
  // const glowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const line = lineRef.current!;
    line.animate(
      [
        { opacity: 1 },
        // { opacity: 0, offset: 0 },
        { opacity: 0, offset: 0.1 },
        { opacity: 1, offset: 0.2 },
        { opacity: 0, offset: 0.25 },
        { opacity: 0, offset: 0.5 },
        { opacity: 1, offset: 0.55 },
        { opacity: 1 },
      ],
      { iterations: Infinity, duration: 4500, delay: 3000 }
    );
  }, []);

  return (
    <div className={Style.hero}>
      <div className={Style.logoMobile}>
        <div
          // style={{
          //   display: 'flex',
          //   flexDirection: 'column',
          //   alignItems: 'center',
          // }}
          className={`mdc-typography--headline1`}>
          <div>Abbey's</div>
          <div
            style={{ fontSize: '.2em', marginTop: '-16px' }}
            className={`mdc-typography--headline6`}>
            Restaurant & Lounge
          </div>
        </div>

        <div
          style={{
            textTransform: 'uppercase',
            padding: '40px',
            border: '2px solid white',
            backgroundColor: 'black',
            borderRadius: '12px',

            display: 'flex',
            gap: '8px',
            flexDirection: 'column',
          }}>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <svg width='52px' height='67px' viewBox='0 0 52 67' version='1.1'>
              <title>glass</title>
              <g
                id='Page-1'
                stroke='none'
                strokeWidth='1'
                fill='none'
                fillRule='evenodd'>
                <g
                  id='glass'
                  transform='translate(-49.000000, -16.000000)'
                  strokeWidth='2'>
                  <g transform='translate(49.324219, 16.699505)'>
                    <path
                      d='M14.2869061,65.3004946 L36.5568439,65.3004946 C37.1091287,65.3004946 37.5568439,64.8527794 37.5568439,64.3004946 C37.5568439,63.8398384 37.2421573,63.4387814 36.7947246,63.3292002 L26.421875,60.7887759 L26.421875,60.7887759 L26.421875,44.3004946 L49.7466963,10.8727278 C50.0627319,10.419804 49.9517621,9.79643926 49.4988384,9.48040363 C49.3309982,9.36329016 49.1312657,9.30049462 48.9266053,9.30049462 L1.91714474,9.30049462 C1.36485999,9.30049462 0.91714474,9.74820987 0.91714474,10.3004946 C0.91714474,10.505155 0.979940277,10.7048876 1.09705375,10.8727278 L24.421875,44.3004946 L24.421875,44.3004946 L24.421875,60.7887759 L14.0490254,63.3292002 C13.5125944,63.4605781 13.1842338,64.0019442 13.3156117,64.5383752 C13.4251928,64.985808 13.8262499,65.3004946 14.2869061,65.3004946 Z'
                      id='outline'
                      stroke='#FFFFFF'></path>
                    <line
                      x1='6.37785059'
                      y1='18.9638584'
                      x2='25.421875'
                      y2='18.9638584'
                      id='level'
                      stroke='#FFFFFF'></line>
                    <polyline
                      ref={lineRef as any}
                      id='straw'
                      stroke='#FF5722'
                      points='28.84375 26 38.3633891 12.2494101 46.84375 -4.61852778e-14'></polyline>
                  </g>
                </g>
              </g>
            </svg>

            <div
              style={{
                fontSize: '64px',
                fontWeight: '300',
              }}>
              OPEN
            </div>
          </div>

          <Hours />
        </div>

        <div className={Style.flexList}>
          <Button
            href="https://www.google.com/maps/dir//Abbey's+Restaurant+%26+Lounge,+145+Zane+St,+Wheeling,+WV+26003/@40.072704,-80.7379087,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x8835d990fc0f2cc9:0xf7e16d5d63a669fe!2m2!1d-80.73572!2d40.072704!3e0"
            text='Directions'
            icon={'map'}
          />
          {/* <Button text='Hours' icon={'schedule'} /> */}
          <Button
            href='https://www.facebook.com/abbeysrest'
            text='Facebook'
            icon={'facebook'}
          />
        </div>
      </div>
    </div>
  );
}
