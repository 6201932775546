// import Style from './Icon.module.scss';

export type IconProps = { name: string };

export function Icon(props: IconProps) {
  return (
    <i className='material-icons mdc-button__icon' aria-hidden='true'>
      {props.name}
    </i>
  );
}
