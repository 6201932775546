export interface MenuItem {
  title: string | string[];
  price: string;
  discription: string | string[];
  caption?: string;
  supplementalNodes?: string[];
}

export const phoneNumber = '(304) 233-0729';

export const appetizers: MenuItem[] = [
  {
    title: 'Whole Blue Mussels',
    discription:
      'Steamed and served with a white wine and butter garlic sauce, and topped with diced tomatoes',
    price: '$11.99',
  },
  {
    title: 'Cheesy Bacon Fries',
    discription: '',
    price: '$11.99',
  },
  {
    discription: 'Banana peppers stuffed with mild Italian sausage',
    title: 'Stuffed Banana Peppers',
    price: '$11.99',
  },
  {
    discription: '',
    title: 'Fried Zucchini',
    price: '$7.99',
  },
  {
    discription: '',
    title: 'Fried Mushrooms',
    price: '$6.99',
  },
  {
    discription: '',
    title: 'Fried Cauliflower',
    price: '$6.99',
  },
  {
    discription:
      'Freshly fried mushrooms, zucchini and cauliflower in our homemade batter',
    title: 'Fried Veggies',
    price: '$12.99',
  },
  {
    discription: '',
    title: 'Artichoke Dip',
    price: '$9.99',
  },
  {
    discription: '',
    title: 'Cheese Sticks',
    price: '$6.99',
  },

  {
    discription:
      'Two pounds of Salami, cheddar, Swiss, and pepper jack with olives',
    title: `Abbey's Cheese Plate`,
    price: '$11.99',
  },

  {
    discription: 'Fresh jumbo shrimp (6) served with homemade cocktail sauce',
    title: 'Jumbo Shrimp Cocktail',
    price: '$15.99',
  },
  {
    discription: 'Crispy fried shrimp served with homemade cocktail sauce',
    price: '$6.99',
    title: '21 Shrimp in a Basket',
  },
  {
    title: 'Chicken Tenders',
    price: '$8.99',
    discription:
      'Fried chicken tenderloins (5) served with your choice of dressings',
  },
  {
    discription:
      'A half slow-roasted rotisserie chicken with our special seasonings',
    title: 'Chicken Sampler',
    price: '$8.99',
  },
  {
    discription:
      'A half slab of dry-rubbed rotisserie pork ribs with our special seasoning',
    title: 'Ribs Sampler',
    price: '$12.99',
  },
  {
    discription: 'Freshly battered and deep fried onion rings in a crispy loaf',
    title: 'Onion Ring Loaf',
    price: '$10.99',
  },
];

export const rotisserie: MenuItem[] = [
  {
    title: 'Rotisserie Chicken Dinner',
    price: '$13.99',
    discription: 'Half a slow roasted chicken',
  },

  {
    title: 'Rotisserie Ribs Dinner',
    discription: ['A half slab of dry-rubbed', 'rotisserie pork ribs'],
    price: '$16.99',
  },
];

export const meats: MenuItem[] = [
  ...rotisserie,
  {
    title: `Sirloin Steak`,
    price: '$18.99',
    discription: `Abbey's famous sirloin steak (10 oz)`,
  },
  {
    title: 'Open Face Steak',
    price: '$23.99',
    discription: 'NY strip steak (12 oz) served over toast',
  },
  {
    title: 'Stuffed NY Strip Steak',
    discription: [
      'NY strip steak (12 oz) stuffed with',
      'fresh herbs, gulf shrimp,',
      'provolone and mozzarella cheese, and',
      'topped with grilled mushrooms',
    ],
    price: '$34.99',
  },
  {
    title: ['Black & Bleu NY Strip Steak'],
    discription: 'Cajun broiled NY strip steak (12 oz) topped with bleu cheese',
    price: '$25.99',
  },
  {
    title: ['NY Strip Steak & Jumbo Fried Shrimp'],
    discription: [
      'Our popular NY strip steak (12 oz),',
      'and three jumbo fried shrimp',
    ],
    price: '$29.99',
  },
  {
    discription: [`Abbey's most lean and tender`, `aged steak (10 oz)`],
    title: 'Filet Mignon',
    price: '$27.99',
  },
  {
    discription: [
      `Abbey's famous tender prime rib (14 oz)`,
      `Available Friday, Saturday & Sunday in limited quantity`,
    ],
    title: `Prime Rib`,
    price: '$31.99',
  },
  {
    title: 'Pork Chops',
    discription: ['Two tender and juicy thick cut chops', '(8 oz each)'],
    price: '$16.99',
  },
];

export const sideDishes: MenuItem[] = [
  {
    discription: '',
    title: 'Baked Potato',
    price: '$2.50',
  },
  {
    discription: '',
    title: 'French Fries',
    price: '$1.99',
  },
  {
    discription: '',
    title: 'Cottage Cheese',
    price: '$1.99',
  },
  {
    discription: '',
    title: 'Cole Slaw',
    price: '$1.99',
  },
  {
    title: 'Applesauce',
    discription: '',
    price: '$1.99',
  },
  {
    discription: '',
    title: 'Vegetable of the Day',
    price: '$1.99',
  },
  {
    title: 'Spaghetti',
    discription: '',
    price: '$3.99',
  },
  {
    title: 'Ravioli',
    discription: '',
    price: '$6.99',
  },
  {
    title: 'Rigatoni',
    discription: '',
    price: '$3.99',
  },
  {
    title: 'Onion Rings',
    discription: '',
    price: '$3.25',
  },
];

export const pasta: MenuItem[] = [
  {
    title: `Abbey's Pasta Special`,
    price: '$19.99',
    discription: [
      'Linguini tossed in a white garlic butter sauce',
      'with fresh spinach, artichokes roasted red',
      'bell peppers, tomatoes, and topped with',
      'feta cheese',
    ],
  },
  {
    title: `Abbey's Chicken Pasta Special`,
    price: '$24.99',
    discription: ``,
  },
  {
    title: `Abbey's Jumbo Shrimp Pasta Special`,
    price: '$26.99',
    discription: '',
  },
  {
    title: 'Whole Blue Mussels',
    discription:
      'A white wine and butter garlic sauce served over linguini, and topped with whole blue mussels (1 lb) and diced tomatoes',
    price: '$18.99',
  },
  {
    discription: '',
    title: 'Chicken Parmesan',
    price: '$15.99',
  },
  {
    discription: '',
    title: 'Cheese Ravioli',
    price: '$14.99',
  },
  {
    title: 'Spaghetti with Meatballs',
    discription: '',
    price: '$13.99',
  },
  {
    title: 'Rigatoni with Meatballs',
    discription: '',
    price: '$13.99',
  },
  {
    title: 'Chicken Alfredo',
    discription: '6oz chicken breast',
    price: '$21.99',
  },
  {
    title: 'Shrimp Alfredo',
    discription: '6 jumbo grilled shrimp',
    price: '$24.99',
  },
  {
    title: 'Shrimp Scampi',
    discription:
      '6 shrimp served with a white wine and butter garlic sauce topped with diced tomatoes and served over linguini',
    price: '$20.99',
  },
];

export const seafood: MenuItem[] = [
  {
    discription: '',
    title: 'Fresh Tilapia 8oz',
    price: '$14.99',
  },
  {
    discription: '',
    title: 'Broiled Canadian White Fish 12oz',
    price: '$16.99',
  },
  {
    discription: '',
    title: 'Fried Canadian White Fish 12oz',
    price: '$16.99',
  },
  {
    title: 'Yellow Fin Tuna Steak 8oz',
    discription: '',
    price: '$18.99',
  },
  {
    title: 'Norwegian Salmon 8oz',
    discription: '',
    price: '$18.99',
  },
  {
    title: 'Jumbo Deep Fried Shrimp 6ct',
    discription: '',
    price: '$16.99',
  },
  {
    title: 'Surf & Turf',
    discription: `Abbey's perfectly aged filet mignon (10 oz), and succulent South African cold water lobster tail (9 oz)`,
    price: 'Market',
  },
  {
    title: `Joey's Jumbo Lobster Tail`,
    discription: `Succulent South African cold water lobster tail`,
    price: 'Market',
  },
];

export const sandwiches: MenuItem[] = [
  {
    discription: '',
    title: 'Grilled Cheese',
    price: '$8.99',
  },
  {
    discription: '',
    title: 'Meatball',
    price: '$10.99',
  },
  {
    discription: '',
    title: 'Hot Sausage - 12oz',
    price: '$11.99',
  },
  {
    discription: '',
    title: 'Hot Ham  - 10oz',
    price: '$11.99',
  },
  {
    discription: '',
    title: 'Grilled Chicken',
    price: '$11.99',
  },

  {
    discription: '',
    title: 'Hamburger - 12oz',
    price: '$11.99',
  },

  {
    discription: '',
    title: 'Cheeseburger - 12oz',
    price: '$12.49',
  },

  {
    discription: '',
    title: 'Fried Fish - 8oz',
    price: '$11.99',
  },
];

export const gardenSalads: MenuItem[] = [
  {
    title: 'Spinach Salad',
    // discription: 'Fresh cut spinach topped with <INFO REQUIRED>',
    discription: '',
    price: '$9.99',
  },

  {
    title: 'Chicken Salad',
    // discription: 'Topped with grilled rotisserie chicken, and <INFO REQUIRED>',
    discription: '',
    price: '$12.99',
  },

  {
    title: 'Steak Salad',
    // discription:
    // 'Topped with prime filet medalions topped with <INFO REQUIRED>',
    discription: '',
    price: '$13.99',
  },

  {
    title: 'Seafood Salad',
    // discription: 'Crab and shrimp topped with <INFO REQUIRED>',
    discription: '',
    price: '$14.99',
  },

  {
    title: 'Grilled Salmon Salad',
    // discription:
    //   'Freshly caught Alantic grilled salmon topped with <INFO REQUIRED>',
    discription: '',
    price: '$13.99',
  },

  {
    title: 'Grilled Tuna Steak Salad',
    // discription:
    //   'Blackened and grilled Atlantic tuna steak topped with <INFO REQUIRED>',
    discription: '',
    price: '$13.99',
  },

  {
    title: 'Mediterranean Salad',
    // discription: `<INFO REQUIRED>`,
    discription: '',
    price: '$7.99',
  },
  {
    title: 'Tomato Salad',
    // discription: '<INFO REQUIRED>',
    discription: '',
    price: '$11.99',
  },
  {
    title: 'Caesar Salad',
    // discription: '<INFO REQUIRED>',
    discription: '',
    price: '$7.99',
  },
  {
    title: 'Chef Salad',
    // discription: `Fresh bed of greens topped with ham, egg, Swiss, American and mozzarella cheese`,
    discription: '',
    price: '$14.99',
  },
  {
    discription: ``,
    title: 'Tossed Salad',
    price: '$3.99',
  },
  // {
  //   title: 'Make your own',
  //   discription: 'Lorem Ipsum is simply dummy text of the printing ',
  //   price: '$14.99',
  // },
];

export const menu = {
  appetizers,
  meats,
  rotisserie,
  sandwiches,
  gardenSalads,
  sideDishes,
  pasta,
  seafood,
};
