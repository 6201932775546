import Style from './MenuComp.module.scss';

import { MenuSection } from '../../types/MenuSection';
import { MenuSectionComp } from './menuSection';

export type MenuCompProps = {
  cols: MenuSection[][];
};

export function MenuComp(props: MenuCompProps) {
  return (
    <div className={Style.root}>
      {props.cols.map((sections, i) => (
        <div key={i} className={Style.col}>
          {sections.map((d, i) => (
            <MenuSectionComp key={i} {...d} />
          ))}
        </div>
      ))}
    </div>
  );
}
