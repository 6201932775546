import React from 'react';
import { phoneNumber } from '../../store/data/menuData';

import Style from './Footer.module.css';

export interface FooterProps {}

export function Footer() {
  return (
    <footer className={Style.footer}>
      <div className={Style.description}>145 Zane St, Wheeling, WV 26003</div>
      <div className={Style.description}>{phoneNumber}</div>
      <div style={{ opacity: 0.5 }} className={Style.description}>
        Design by{' '}
        <a href='https://bradysterling.dev' style={{ color: 'white' }}>
          bradysterling.dev
        </a>
      </div>
    </footer>
  );
}
