import Style from './LunchMenu.module.scss';

import { lunchMenuData } from '../../../store/data/lunchMenuData';
import { MenuComp } from '../../../components/menu';

export type LunchMenuPageProps = {};

export function LunchMenuPage(_props: LunchMenuPageProps) {
  return (
    <div className={Style.root}>
      <MenuComp cols={lunchMenuData} />
    </div>
  );
}
