export enum MenuDisplayStatus {
  dismissed = 'dismissed',
  presenting = 'presenting',
  dismissing = 'dismissing',
  presented = 'presented',
}

export type NavigationMenu = {
  displayStatus: MenuDisplayStatus;
};
