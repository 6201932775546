import Style from './MenuPage.module.css';

import { SharedProps } from '../../types/SharedProps';
import { PageId } from '../../enums/PageId';
import { Nav } from '../../components/nav';
import { DinnerMenu } from './dinner';
import { LunchMenuPage } from './lunch';
import { DrinksMenuPage } from './drinks';
import { useEffect, useRef } from 'react';

export type MenuProps = SharedProps;

export function MenuPage(props: MenuProps) {
  const headerRef = useRef<HTMLDivElement>(null);
  const navigate = props.router.navigate;

  const localState = props.store.getters.productMenu;
  const menuId = localState.menuId;

  function MenuContent() {
    switch (menuId) {
      case PageId.dinner:
        return <DinnerMenu />;
      case PageId.lunch:
        return <LunchMenuPage />;
      case PageId.drinks:
        return <DrinksMenuPage />;
      default:
        return <DinnerMenu />;
    }
  }

  function navigateToMenuSection(pageId: PageId) {
    return () => {
      navigate({
        segments: [{ pageId: PageId.menu }, { pageId }],
      });
    };
  }

  useEffect(() => {
    headerRef.current?.scrollTo({ top: 0 });
  }, [menuId]);

  return (
    <div className={Style.root}>
      <div ref={headerRef} className={Style.header}>
        <div className={'mdc-typography--headline2'}>Menus</div>
        <Nav
          items={[
            {
              title: 'Dinner',
              selected: menuId === undefined || menuId === PageId.dinner,
              onClick: navigateToMenuSection(PageId.dinner),
            },
            {
              title: 'Lunch',
              selected: menuId === PageId.lunch,
              onClick: navigateToMenuSection(PageId.lunch),
            },
            {
              title: 'Drinks',
              selected: menuId === PageId.drinks,
              onClick: navigateToMenuSection(PageId.drinks),
            },
          ]}
        />
      </div>

      <MenuContent />
    </div>
  );
}
