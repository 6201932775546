import Style from './Appbar.module.css';

import React from 'react';

import { Button, ButtonProps } from '../button';
import { IconButton, IconButtonProps } from '../button/IconButton';

export interface AppbarProps {
  menuIcon: IconButtonProps;
  title?: string;
  orderButton?: ButtonProps;
}

export function Appbar(props: AppbarProps) {
  return (
    <div className={Style.appbar}>
      <div className={Style.segment}>
        <div className={Style.presentIcon}>
          <IconButton {...props.menuIcon} />
        </div>
        {props.title && (
          <div
            style={{ fontFamily: 'var(--accent-font)' }}
            className={`mdc-typography--headline5`}>
            {props.title}
          </div>
        )}
      </div>

      <div className={Style.segment}>
        <div className={`${Style.contactInfo} mdc-typography--body2`}>
          {/* <span className='mdc-typography--body2'>{phoneNumber}</span> */}
          {props.orderButton && <Button {...props.orderButton} />}
        </div>
      </div>
    </div>
  );
}
