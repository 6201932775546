import { CSSProperties } from 'react';

export type NavItemProps = {
  title: string;
  selected: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export function NavItem(props: NavItemProps) {
  const style: CSSProperties = {
    width: 'min-content',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'var(--body-font-family)',
    fontWeight: 'normal',
    fontSize: '16px',
    letterSpacing: 0,
    color: 'rgba(255, 255, 255, 1)',
    textDecoration: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
    outline: 'none',
    border: 'none',
    background: 'transparent',
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
  };
  const outLineStyle: CSSProperties = {
    backgroundColor: props.selected ? 'var(--primary-color)' : 'white',
    height: '1px',
    width: '100%',
  };
  const titleStyle = {
    padding: '4px 32px',
  };

  return (
    <button style={style} onClick={props.onClick}>
      <div style={titleStyle}>{props.title}</div>
      <div style={outLineStyle}></div>
    </button>
  );
}
