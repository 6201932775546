import Style from './App.module.scss';

import Appbar from '../../components/appbar';

import { useEffect, useRef } from 'react';
import { PageId } from '../../enums/PageId';
import { useRouter } from '../../router/useRouter';
import { useStore } from '../../store';
import { phoneNumber } from '../../store/data/menuData';
import { MenuDisplayStatus } from '../../types/NavigationMenu';
import { SharedProps } from '../../types/SharedProps';
import { Footer } from '../footer';
import { Home } from '../home';
import { MenuPage } from '../menu/MenuPage';
import { NavigationMenu } from '../navigationMenu';
import { ContactPage } from '../contact/ContactPage';

// import browserPlugin from 'router5-plugin-browser';
// import { useRoute } from 'react-router5';

function App() {
  const appRef = useRef<HTMLDivElement>(null);

  const store = useStore();
  const { getters, setters } = store;
  const router = useRouter({ store });

  function Page(props: SharedProps) {
    const p = props.store.state.route.pageId;
    const Id = PageId;

    const sharedProps = { router, store };

    if (p === Id.home ?? p === Id.null) return <Home {...props} />;
    else if (p === Id.menu) return <MenuPage {...sharedProps} />;
    else if (p === Id.contact) return <ContactPage {...sharedProps} />;
    else return <div></div>;
  }

  const displayStatus = getters.navigationMenu.displayStatus;

  useEffect(() => {
    const el = appRef.current;

    const blurEffect = [
      {
        filter: 'blur(0)',
        transition: 'filter 125ms ease-in-out',
      },
      {
        filter: 'blur(8px)',
        transition: 'filter 125ms ease-in-out',
      },
    ];

    const defaultOpts = {
      duration: 150,
      easing: 'ease-in',
    } satisfies KeyframeAnimationOptions;

    if (el) {
      if (displayStatus === MenuDisplayStatus.presenting) {
        el.animate(blurEffect, defaultOpts);
        el.style.filter = blurEffect[1].filter;
      } else if (displayStatus === MenuDisplayStatus.dismissing) {
        el.style.filter = 'blur(0)';
        setters.dismissNavigationMenu({
          displayStatus: MenuDisplayStatus.dismissed,
        });
      }
    }
  }, [displayStatus, setters]);

  useEffect(() => {
    const el = appRef.current;

    function cb(ev: AnimationEvent) {
      if (el) {
        if (displayStatus === MenuDisplayStatus.presenting) {
          setters.presentNavigationMenu({
            displayStatus: MenuDisplayStatus.presented,
          });
        }
      }
    }

    window.addEventListener('animationend', cb);
    return () => window.removeEventListener('animationend', cb);
  }, [displayStatus, setters]);

  return (
    <div>
      <div ref={appRef} className={Style.App}>
        <Appbar
          title={`Abbey's`}
          menuIcon={{
            icon: 'menu',
            onClick() {
              setters.presentNavigationMenu({
                displayStatus: MenuDisplayStatus.presenting,
              });
            },
          }}
          orderButton={{
            href: `tel:${phoneNumber}`,
            icon: 'phone',
            text: 'Order Takeout',
          }}
        />

        <div style={{ flex: 1 }}>
          <Page store={store} router={router} />
        </div>

        <Footer />
      </div>

      {getters.navigationMenu.presented && (
        <NavigationMenu
          {...getters.navigationMenu}
          menuCallback={router.navigate}
          onDismiss={() => {
            setters.dismissNavigationMenu({
              displayStatus: MenuDisplayStatus.dismissing,
            });
          }}
        />
      )}
    </div>
  );
}

export default App;
