import Style from './Nav.module.css';
import { NavItem, NavItemProps } from './NavItem';

export type NavProps = {
  items: NavItemProps[];
};

export function Nav(props: NavProps) {
  return (
    <div className={Style.root}>
      <div className={Style.contentContainer}>
        {props.items.map((p, i) => (
          <NavItem key={i} {...p} />
        ))}
      </div>
    </div>
  );
}
