import { MenuComp } from '../../../components/menu';
import { MenuSection } from '../../../types/MenuSection';

export type DrinksMenuPageProps = {};

export function DrinksMenuPage(props: DrinksMenuPageProps) {
  return (
    <div
      style={{
        maxWidth: '1024px',
      }}>
      <MenuComp cols={drinksMenu} />
    </div>
  );
}

// footer: [
//   // 'Upcharge for Top Shelf Alcohol',
//   // 'Some ingredients are seasonal and subject to limited availability',
// ],

const martinis: MenuSection = {
  title: 'Martinis',
  items: [
    {
      title: `Andy's Martini`,
      description: `Traditional Gin or Vodka And make either dirty with fresh olive juice, olives, blue cheese stuffed olives, or feta cheese stuffed olives.`,
      price: '$7',
    },
    {
      title: `Berry Smash `,
      price: `$8`,
      description: `Blueberry vodka, whip vodka, sour mix, dash simple syrup, dash Sierra Mist, fresh muddled blueberries, raspberries, blackberries.`,
    },
    {
      title: `Trifecta`,
      price: `$8`,
      description: `Mango rum, pineapple rum, coconut rum, pineapple juice, cranberry juice.`,
    },
    {
      title: `Sour Apple`,
      price: `$8`,
      description: `Vodka, sour apple pucker, splash simple syrup and drizzled caramel glass.`,
    },
    {
      title: `Chocolate `,
      price: `$8`,
      description: `Kahlua, Bailey's, vanilla vodka and chocolate drizzled glass.`,
    },
    {
      title: `Iced Coffee `,
      price: `$9`,
      description: `Kahlua, vanilla vodka, mocha frappuchino, topped with whipped cream, coffee bean and chocolate.`,
    },
    {
      title: `Charlotte's Bellini `,
      price: `$7`,
      description: `Vodka, peach schnapps, pineapple juice`,
    },
  ],
};

const crushes: MenuSection = {
  title: `Abbey's Fresh Crushes`,
  items: [
    {
      title: `Orange Crush`,
      price: `$7`,
      description: `Orange vodka, triple seC, splash Sierra Mist and fresh squeezed orange juice.`,
    },
    {
      title: `Grapefruit Crush`,
      price: `$7`,
      description: `Grapefruit vodka, triple sec, splash simple syrup, splash Sierra Mist and fresh squeezed grapefruit`,
    },
    {
      title: 'Lemonade Crush',
      price: `$7`,
      description:
        'Lemon vodka, simple syrus Sierra Mist and fresh squad lemon juice.',
    },
    {
      title: `Strawberry Lemonade Crush`,
      price: `$7`,
      description: `Lemon vodka, simple syrup, splash Sierra Mist, strawberry pureé, and fresh squeezed lemon juice.`,
    },
  ],
};

const mules: MenuSection = {
  title: `Mules`,
  items: [
    {
      title: `Moscow Mule`,
      price: `$7`,
      description: `Vodka, ginger beer, fresh lime juice`,
    },
    {
      title: `Kentucky Mule`,
      price: `$7`,
      description: `Bourbon, ginger beer, fresh lime juice`,
    },
    {
      title: `Mexican Mule`,
      price: `$7`,
      description: `Tequila, ginger beer, fresh lime juice`,
    },
  ],
};

const specials: MenuSection = {
  title: `Specials`,
  items: [
    // Floating
    {
      title: `Red or White wine Sangria`,
      price: `$9`,
      description: `Blackberry Merlot or moscato wine with fresh orange, lemon, lime, cherries and splash Sierra Mist.`,
    },
    {
      title: `Traditional Mojito`,
      price: `$8`,
      description: `Muddled mint, lime, sugar, soda water, rum`,
    },
    {
      title: `Flavored Mojitos`,
      price: `$9`,
      description: `Raspberry, Strawberry, Peach or Piña Colada`,
    },

    { title: `Original Lime Margarita`, price: `$7` },

    {
      title: `Flavored Margaritas`,
      description: 'Strawberry, Raspberry or Peach',
      price: `$8`,
    },

    { title: `Italian Magarita Amaretto Float`, price: `$9` },

    {
      title: `Neptunia Gin Fizz`,
      price: `$2`,
      description: `Hendricks gin, fresh squeezed lime juice, lime infused simple syrup, fresh cucumber and splash soda water`,
    },

    {
      title: `Merrymint Cucumber`,
      price: `$8`,
      description: `Cucumber lime infused vodka, fresh squeezed lime juice, lime infused simple syrup, muddled mint and cucumber, splash of soda water`,
    },
    {
      title: `Daiquiri`,
      description: 'Strawberry, Raspberry or Peach',
      price: `$7`,
    },

    {
      title: `Piña Colada`,
      price: `$7`,
    },
  ],
};
const classics: MenuSection = {
  title: `Classics`,
  items: [
    // Original
    { title: `Bloody Mary`, price: `$7` },
    { title: `Old Fashioned`, price: `$7` },
    { title: `Manhattan`, price: `$7` },
    { title: `Whiskey Sour`, price: `$7` },
    { title: `Amaretto Sour`, price: `$7` },
    { title: `Tom Collins`, price: `$7` },
    { title: `White Russian`, price: `$8` },
    { title: `Black Russian`, price: `$8` },
    { title: `Cosmopolitan`, price: `$8` },
    { title: `Long Island Ice Tea`, price: `$9` },
  ],
};

const drinksMenu: MenuSection[][] = [
  [specials, classics, mules],
  [martinis, crushes],
];
