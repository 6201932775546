const hoursSchedule = [
  {
    day: 0,
    weekday: 'Sunday',
    hours: {
      start: '4PM',
      end: '8PM',
    },
  },
  {
    day: 1,
    weekday: 'Monday',
    hours: {
      start: '3PM',
      end: '9PM',
    },
  },
  {
    day: 2,
    weekday: 'Tuesday',
    hours: {
      start: '3PM',
      end: '9PM',
    },
  },
  {
    day: 3,
    weekday: 'Wednesday',
    hours: {
      start: '3PM',
      end: '9PM',
    },
  },
  {
    day: 4,
    weekday: 'Thursday',
    hours: {
      start: '11AM',
      end: '9PM',
    },
  },
  {
    day: 5,
    weekday: 'Friday',
    hours: {
      start: '11AM',
      end: '9:30PM',
    },
  },
  {
    day: 6,
    weekday: 'Saturday',
    hours: {
      start: '4PM',
      end: '10PM',
    },
  },
];

const makeSchedule = () => {
  const range = [0, 1, 2, 3, 4, 5, 6].map((index) => {
    const date = new Date();
    date.setDate(date.getDate() + index);
    return date;
  });

  const orderedSchedule = range.map((date) => {
    const day = date.getDay();
    const dateData = hoursSchedule[day];
    return dateData;
  });

  return orderedSchedule;
};

export const scheduleController = {
  makeSchedule,
  hoursSchedule,
};
