import { PageId } from '../enums/PageId';
import { GlobalState } from '../types/GlobalState';
import { MenuDisplayStatus } from '../types/NavigationMenu';

export const initState: GlobalState = {
  navigationMenu: {
    displayStatus: MenuDisplayStatus.dismissed,
  },
  route: {
    init: false,
    pageId: PageId.null,
    path: window.location.pathname,
    segments: [],
  },
};
