import { useCallback, useEffect } from 'react';

import { PageId } from '../enums/PageId';
import { Store } from '../store/useStore';
import { PushRoute } from './types/PushRoute';
import { Route } from './types/Route';
import { RouteSegment } from './types/RouteSegment';

export type Router = ReturnType<typeof useRouter>;

export function useRouter(params: { store: Store }) {
  const { store } = params;

  const setters = store.setters;
  const route = store.state.route;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [route]);

  function parseRoute({ path }: { path: string }): Omit<Route, 'init'> {
    const stringSegments = path.split('/').slice(1);
    const segments: RouteSegment[] = stringSegments.map((s) => {
      const segmentParams = s.split(';');
      const newSegment: RouteSegment = {
        pageId: (segmentParams.at(0) ?? PageId.null) as PageId,
        params: segmentParams.slice(1)?.reduce((a, c) => {
          const [k, v] = c.split('=');
          return { ...a, [k]: v };
        }, {}),
      };

      return newSegment;
    });

    /** Validate string and disallow invalid pages */
    const pageIdList = Object.values(PageId);
    const pageId = segments.at(0)?.pageId as PageId;
    const isValid = pageIdList.includes(pageId);

    /** disallow invalid pages */
    if (isValid) {
      return { pageId, path, segments };
    } else {
      if (process.env.NODE_ENV === 'development') {
        throw Error('>>> INVALID ROOT PATH');
      }
      return { pageId: PageId.home, path, segments };
    }
  }

  const navigate = useCallback((pushRoute: PushRoute) => {
    function parsePath(pushRoute: PushRoute): string {
      return pushRoute.segments.reduce((url, segment) => {
        const params = segment.params
          ? Object.entries(segment.params).reduce(
              (a, [k, v]) => `${a};${[k]}=${v}`,
              ''
            )
          : '';
        return `${url}/${segment.pageId}${params}`;
      }, '');
    }

    const newRoute: Route = {
      init: true,
      path: parsePath(pushRoute),
      pageId: pushRoute.segments.at(0)?.pageId ?? PageId.null,
      ...pushRoute,
    };

    if (newRoute.pageId) {
      window.history.pushState(newRoute, newRoute.pageId, newRoute.path);

      return newRoute;
    } else {
      throw Error('Missing property');
    }
  }, []);

  useEffect(() => {
    onpopstate = (event) => {
      console.log(`----- popstate listener #2`, event);
      const route = parseRoute({ path: `/${event.state.pageId}` });
      setters.setRoute({ init: true, ...route });
    };

    if (route.init === false) {
      const route = parseRoute({ path: window.location.pathname });
      setters.setRoute({ init: true, ...route });
    }

    return () => {
      window.removeEventListener('popstate', onpopstate!);
    };
  }, [route.init, setters]);

  /** POST INIT */
  useEffect(() => {
    if (route.init && route.pageId === PageId.null) {
      const newRoute = navigate({ segments: [{ pageId: PageId.home }] });
      setters.setRoute(newRoute);
    }
  }, [navigate, route.init, route.pageId, setters]);

  return {
    navigate: (p: PushRoute) => {
      if (p) {
        const newRoute = navigate(p);
        setters.setRoute(newRoute);
      }
    },
  };
}
