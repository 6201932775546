import { MenuSection } from '../../types/MenuSection';

export const lunchMenuData: MenuSection[][] = [
  [
    {
      title: 'SANDWICH PLATTERS',
      description: `Sandwich platters include your choice of sandwich and any 2 of the following: Cole slaw, cottage cheese, apple sauce, vegetable of the day, mini tossed salad, French fries, onion rings, baked potato, or cup of soup`,
      items: [
        { title: 'Grilled Cheese', price: '$6.99' },
        { title: 'Meatball', price: '$7.99' },
        { title: 'Hot Sausage', price: '$7.99' },
        { title: 'Hot Ham', price: '$7.99' },
        { title: 'Grilled Chicken', price: '$8.99' },
        { title: 'Hamburger', price: '$7.99' },
        { title: 'Cheeseburger', price: '$8.59' },
        { title: 'Fish', price: '$8.99' },
        { title: 'BLT', price: '$7.99' },
        { title: 'Club Turkey Ham', price: '$8.99' },
      ],
    },

    {
      title: 'WRAPS',
      description: `Wrap platters include your choice of wrap and any 2 of the following: Cole slaw, cottage cheese, apple sauce, vegetable of the day, mini tossed salad, French fries, onion rings, baked potato, or cup of soup`,
      items: [
        { title: 'Grilled Chicken Wrap', price: '$7.99' },
        { title: 'Fried Chicken Wrap', price: '$7.99' },
        { title: 'Buffalo Chicken Wrap', price: '$7.99' },
        { title: 'Blackened Chicken Spinach Wrap', price: '$7.99' },
      ],
    },

    {
      title: 'ENTREES',
      description: `Entree platters include luncheon toss salad and one side dish`,
      items: [
        { title: 'Broiled Canadian Whitefish (8oz)', price: '$8.99' },
        { title: 'Fried Canadian Whitefish (6oz)', price: '$8.99' },
        { title: 'Yellow Fin Tuna Steak (4oz)', price: '$8.99' },
        { title: 'Norwegian Salmon (4oz)', price: '$8.99' },
        { title: '1 Pork Chop', price: '$8.99' },
      ],
    },
  ],
  [
    {
      title: 'PASTA ENTREES',
      description: `Pasta entrees Include luncheon tossed salad`,
      items: [
        {
          title: `Abbey's Pasta Special`,
          price: '$9.99',
          description:
            'Linguini tossed with fresh spinach, artichoke hearts, roasted bell peppers, and tomatoes finished in a white wine garlic butter sauce topped with feta cheese',
          priceAlts: [
            { title: 'Add chicken breast', price: '$10.99' },
            { title: 'Add jumbo grilled shrimp', price: '$12.99' },
          ],
        },
        { title: 'Chicken Parmesan', price: '$9.99' },
        { title: 'Cheese Filled Ravioli (4)', price: '$7.99' },
        { title: 'Spaghetti with Meatball', price: '$6.99' },
        { title: 'Rigatoni with Meatball', price: '$6.99' },
      ],
    },

    {
      title: 'SALADS',
      description: `Add any cheese for an additional $0.75`,
      items: [
        { title: 'Mini Tossed Salad', price: '$1.99' },
        { title: 'Spinach Salad', price: '$6.99' },
        {
          title: 'Chicken Salad',
          description: 'Grilled rotisserie chicken',
          price: '$8.99',
        },
        {
          title: 'Steak Salad',
          description: 'Prime cuts of Filet Steak',
          price: '$9.99',
        },
        { title: 'Seafood Salad', price: '$8.99' },
        { title: 'Grilled Salmon (4oz) Salad', price: '$8.99' },
        { title: 'Grilled Tuna (4oz) Salad', price: '$8.99' },
        { title: 'Caesar Salad', price: '$3.99' },
        { title: 'Chef Salad', price: '$8.99' },
        { title: 'Tomato Salad', price: '$5.99' },
        {
          title: 'Mediterranean - Caesar- Tomato',
          description:
            'With steak, chicken, tuna steak, salmon filet or shrimp and crabmeat',
          price: '$10.99',
        },
        { title: 'Cup of Soup and Salad', price: '$6.99' },
      ],
    },
  ],
];
