import Style from './MenuSection.module.scss';

import { MenuSection } from '../../../types/MenuSection';
import { MenuItemComp } from '../menuItem';

export function MenuSectionComp(p: MenuSection) {
  return (
    <div className={Style.menuSection}>
      <div className={'mdc-typography--headline3'}>{p.title}</div>
      <div
        style={{ padding: '0 16px', opacity: '0.8' }}
        className={'mdc-typography--body2'}>
        {p.description}
      </div>
      <div className={Style.sectionItemList}>
        {p.items.map((p, i) => (
          <MenuItemComp key={i} {...p} />
        ))}
      </div>

      {p.footer && (
        <div className={Style.footer}>
          {p.footer.map((s, i) => (
            <div key={i} className={'mdc-typography--body2'}>
              {s}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
