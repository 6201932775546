import Style from './NavigationMenu.module.scss';

import Appbar from '../../components/appbar';
import { MenuDisplayStatus } from '../../types/NavigationMenu';
import { PageId } from '../../enums/PageId';
import { PushRoute } from '../../router/types/PushRoute';
import { useRef, useState } from 'react';

export type NavigationMenuProps = {
  menuCallback: (p: PushRoute) => void;
  displayStatus: MenuDisplayStatus;
  onDismiss: () => void;
};

export function NavigationMenu(props: NavigationMenuProps) {
  const rootRef = useRef<HTMLDivElement>(null);
  const rootEl = rootRef.current;

  const animationClass = Style[props.displayStatus];

  function MenuButton(p: {
    title: string;
    color?: string;
    fontSize?: string;
    onClick: () => void;
  }) {
    const scrimRef = useRef<HTMLDivElement>(null);
    const opts: KeyframeAnimationOptions = {
      duration: 100,
      easing: 'ease-out',
    };

    return (
      <button
        className={Style.menuButton}
        onTouchStart={(ev) => {
          const el = scrimRef.current;
          if (el) {
            const fadeIn = [
              { opacity: 0, transform: 'scale(0.5)' },
              { opacity: 1, transform: 'scale(1.0)' },
            ];
            el.animate(fadeIn, opts);
            el.style.opacity = '1';
          }
        }}
        onTouchEnd={() => {
          const el = scrimRef.current;
          if (el) {
            const fadeOut = [
              { opacity: 1, transform: 'scale(1.0)' },
              { opacity: 0, transform: 'scale(0.5)' },
            ];
            el?.animate(fadeOut, opts);
            el.style.opacity = '0';
          }
        }}
        onClick={(ev) => {
          props.onDismiss();
          p.onClick();
        }}
        style={{
          color: p.color ? p.color : 'white',
          fontSize: p.fontSize ?? '1.25em',
        }}>
        {p.title}
        <div className={Style.scrim} ref={scrimRef} />
      </button>
    );
  }

  function menuCallback(pageId: PageId) {
    props.menuCallback({
      segments: [{ pageId: PageId.menu }, { pageId: pageId }],
    });
  }

  function NavigationListItem(p: {
    title: string;
    pageId: PageId;
    children?: JSX.Element | JSX.Element[];
  }) {
    return (
      <div className={Style.navigationListItem}>
        <MenuButton
          title={p.title}
          color='var(--primary-color)'
          fontSize='1.5em'
          onClick={() =>
            props.menuCallback({
              segments: [{ pageId: p.pageId }],
            })
          }
        />
        {p.children}
      </div>
    );
  }

  function NavigationSubList(p: { children?: JSX.Element | JSX.Element[] }) {
    return <div className={Style.navigationSubList}>{p.children}</div>;
  }

  const [eventQueue, dispatch] = useState<React.TouchEvent<HTMLDivElement>[]>(
    []
  );

  const lastEvent = eventQueue.at(-1);
  const firstTouch = lastEvent?.touches.item(0);

  return (
    <div ref={rootRef} className={animationClass}>
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -1,
        }}
        onTouchStart={(ev) => {
          console.log('>>> TOUCH_START', ev);
          dispatch((s) => [...s, ev]);
        }}
        onTouchMove={(ev) => {
          console.log('>>> TOUCH_MOVE', ev);
          if (firstTouch) {
            const lastTouch = ev.changedTouches.item(0);
            const delta = firstTouch.clientX - lastTouch.clientX;
            rootEl!.style.transform = `translateX(-${delta}px)`;
          }
        }}
        onTouchEnd={(ev) => {
          if (firstTouch) {
            const lastTouch = ev.changedTouches.item(0);
            const delta = firstTouch.clientX - lastTouch.clientX;

            if (delta > 66) {
              props.onDismiss();
            } else {
              rootEl!.style.transform = 'translateX(0)';
            }
          }
        }}></div>

      <Appbar
        menuIcon={{
          icon: 'close',
          onClick: props.onDismiss,
        }}
      />

      <div className={Style.navigationList}>
        <NavigationListItem title='Home' pageId={PageId.home} />

        <NavigationListItem title='Menu' pageId={PageId.menu}>
          <NavigationSubList>
            <MenuButton
              title={'Dinner'}
              onClick={() => menuCallback(PageId.dinner)}
            />
            <MenuButton
              title={'Lunch'}
              onClick={() => menuCallback(PageId.lunch)}
            />
            <MenuButton
              title={'Drinks'}
              onClick={() => menuCallback(PageId.drinks)}
            />
          </NavigationSubList>
        </NavigationListItem>

        <NavigationListItem title='Contact' pageId={PageId.contact} />
      </div>
    </div>
  );
}
