import { Button } from '../../components/button';
import { Hours } from '../../components/hours';
import { SharedProps } from '../../types/SharedProps';
import Style from './ContactPage.module.scss';

import mapImage from '../../assets/map_image.png';

export type ContactPageProps = SharedProps;

export function ContactPage(props: ContactPageProps) {
  return (
    <div className={Style.root}>
      <div className={'mdc-typography--headline2'}>Contact</div>
      <div className={Style.content}>
        <div className={Style.section}>
          <div className={'mdc-typography--headline3'}>LOCATION</div>
          <img className={Style.mapImage} src={mapImage} alt='map of Wheeling Island' />
          <div className={Style.addressContainer}>
            <div>Abbey's Restaurant & Lounge</div>
            <div>145 Zane St</div>
            <div>Wheeling, WV 26003</div>
            <div>(304) 233-0729</div>
          </div>

          <div className={Style.buttonContainer}>
            <Button
              href="https://www.google.com/maps/dir//Abbey's+Restaurant+%26+Lounge,+145+Zane+St,+Wheeling,+WV+26003/@40.072704,-80.7379087,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x8835d990fc0f2cc9:0xf7e16d5d63a669fe!2m2!1d-80.73572!2d40.072704!3e0"
              text='Directions'
              icon={'map'}
            />

            {/* <Button text='Hours' icon={'schedule'} /> */}

            <Button
              href='https://www.facebook.com/abbeysrest'
              text='Facebook'
              icon={'facebook'}
            />
          </div>
        </div>

        <div className={Style.section}>
          <div className={'mdc-typography--headline3'}>HOURS</div>

          <Hours />
        </div>
      </div>{' '}
    </div>
  );
}
