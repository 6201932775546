import { SharedProps } from '../../types/SharedProps';
import { Hero } from '../hero';
import Style from './Home.module.scss';

export type HomeProps = SharedProps & {};

export function Home(props: HomeProps) {
  return (
    <div className={Style.root}>
      <Hero />
    </div>
  );
}
