export enum PageId {
  null = '',
  home = 'home',
  contact = 'contact',

  menu = 'menu',
  dinner = 'dinner',
  lunch = 'lunch',
  drinks = 'drinks',
}
