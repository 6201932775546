import { MenuComp } from '../../../components/menu';
import {
  beveragesSection,
  entreesSection,
  pastaSection,
  saladSection,
  sandwichSection,
  seafoodSection,
  sidesSection,
} from '../../../store/data/dinnerMenuData';

import Wings from '../wings/Wings';

export function DinnerMenu() {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '1024px',
      }}>
      <Wings />

      <MenuComp cols={[[sandwichSection], [saladSection]]} />
      <MenuComp cols={[[entreesSection], [seafoodSection, pastaSection]]} />
      <MenuComp cols={[[sidesSection], [beveragesSection]]} />
      {/* <MenuComp cols={[[beveragesSection]]} /> */}
    </div>
  );
}
