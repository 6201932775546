import { MenuSection } from '../../types/MenuSection';

// appetizers,
// entrees,
// sandwiches,
// gardenSalads,
// sideDishes,
// pasta,
// seafood,

export const appetizerSection: MenuSection = {
  title: 'Appetizers',
  items: [
    {
      title: "Abbey's Island Shrimp",
      description: 'Served chilled or spicy Old Bay style',
      priceAlts: [
        { price: '$5.99', title: '1/4 lb' },
        { price: '$9.99', title: '1/2 lb' },
        { price: '$18.99', title: '1 lb' },
      ],
    },
    {
      title: 'Whole Blue Mussels',
      description:
        'Steamed and served with a white wine and butter garlic sauce, and topped with diced tomatoes',
      price: '$11.99',
    },
    {
      title: 'Cheesy Bacon Fries',
      description: '',
      price: '$11.99',
    },
    {
      description: 'Banana peppers stuffed with mild Italian sausage',
      title: 'Stuffed Banana Peppers',
      price: '$11.99',
    },
    {
      description: '',
      title: 'Fried Zucchini',
      price: '$7.99',
    },
    {
      description: '',
      title: 'Fried Mushrooms',
      price: '$6.99',
    },
    {
      description: '',
      title: 'Fried Cauliflower',
      price: '$6.99',
    },
    {
      description:
        'Freshly fried mushrooms, zucchini and cauliflower in our homemade batter',
      title: 'Fried Veggies',
      price: '$12.99',
    },
    {
      description: '',
      title: 'Artichoke Dip',
      price: '$9.99',
    },
    {
      description: '',
      title: 'Cheese Sticks',
      price: '$6.99',
    },

    {
      description:
        'Two pounds of Salami, cheddar, Swiss, and pepper jack with olives',
      title: `Abbey's Cheese Plate`,
      price: '$11.99',
    },

    {
      description: 'Fresh jumbo shrimp (6) served with homemade cocktail sauce',
      title: 'Jumbo Shrimp Cocktail',
      price: '$15.99',
    },
    {
      description: 'Crispy fried shrimp served with homemade cocktail sauce',
      price: '$6.99',
      title: '21 Shrimp in a Basket',
    },
    {
      title: 'Chicken Tenders',
      price: '$8.99',
      description:
        'Fried chicken tenderloins (5) served with your choice of dressings',
    },
    {
      description:
        'A half slow-roasted rotisserie chicken with our special seasonings',
      title: 'Chicken Sampler',
      price: '$8.99',
    },
    {
      description:
        'A half slab of dry-rubbed rotisserie pork ribs with our special seasoning',
      title: 'Ribs Sampler',
      price: '$12.99',
    },
    {
      description:
        'Freshly battered and deep fried onion rings in a crispy loaf',
      title: 'Onion Ring Loaf',
      price: '$10.99',
    },
  ],
};

export const entreesSection: MenuSection = {
  title: 'Meat Entrees',
  description: `All meats hand cut on premises. Entrées include tossed salad, one side dish and bread & butter. $1 extra for ravioli`,
  items: [
    {
      title: 'Rotisserie Chicken Dinner',
      price: '$13.99',
      description: 'Half a slow roasted chicken',
    },

    {
      title: 'Rotisserie Ribs Dinner',
      description: 'A half slab of dry-rubbed rotisserie pork ribs',
      price: '$16.99',
    },
    {
      title: `Sirloin Steak`,
      price: '$18.99',
      description: `Abbey's famous sirloin steak (10 oz)`,
    },
    {
      title: 'Open Face Steak',
      price: '$23.99',
      description: 'NY strip steak (12 oz) served over toast',
    },
    {
      title: 'Stuffed NY Strip Steak',
      description:
        'NY strip steak (12 oz) stuffed with fresh herbs, gulf shrimp, provolone and mozzarella cheese, and topped with grilled mushrooms',
      price: '$34.99',
    },
    {
      title: 'Black & Bleu NY Strip Steak',
      description:
        'Cajun broiled NY strip steak (12 oz) topped with bleu cheese',
      price: '$25.99',
    },
    {
      title: 'NY Strip Steak & Jumbo Fried Shrimp',
      description:
        'Our popular NY strip steak (12 oz) and three jumbo fried shrimp',
      price: '$29.99',
    },
    {
      description: `Abbey's most lean and tender aged steak (10 oz)`,
      title: 'Filet Mignon',
      price: '$27.99',
    },
    {
      description: `Abbey's famous tender prime rib (14 oz). Available Friday, Saturday & Sunday in limited quantity`,
      title: `Prime Rib`,
      price: '$31.99',
    },
    {
      title: 'Pork Chops',
      description: 'Two tender and juicy thick cut chops (8 oz each)',
      price: '$16.99',
    },
  ],
};

export const sidesSection: MenuSection = {
  title: 'Sides',
  items: [
    {
      title: 'Baked Potato',
      price: '$2.50',
    },
    {
      title: 'French Fries',
      price: '$1.99',
    },
    {
      title: 'Cottage Cheese',
      price: '$1.99',
    },
    {
      title: 'Cole Slaw',
      price: '$1.99',
    },
    {
      title: 'Applesauce',
      price: '$1.99',
    },
    {
      title: 'Vegetable of the Day',
      price: '$1.99',
    },
    {
      title: 'Spaghetti',
      price: '$3.99',
    },
    {
      title: 'Ravioli',
      price: '$6.99',
    },
    {
      title: 'Rigatoni',
      price: '$3.99',
    },
    {
      title: 'Onion Rings',
      price: '$3.25',
    },
  ],
};

export const pastaSection: MenuSection = {
  title: 'Pasta Entrees',
  description: `Entrées include tossed salad and bread & butter`,
  items: [
    {
      title: `Abbey's Pasta Special`,
      price: '$19.99',
      description:
        'Linguini tossed in a white garlic butter sauce with fresh spinach, artichokes, roasted red bell peppers, tomatoes, and topped with feta cheese',
    },
    {
      title: `Abbey's Chicken Pasta Special`,
      price: '$24.99',
    },
    {
      title: `Abbey's Jumbo Shrimp Pasta Special`,
      price: '$26.99',
    },
    {
      title: 'Whole Blue Mussels',
      description:
        'A white wine and butter garlic sauce served over linguini, and topped with whole blue mussels (1 lb) and diced tomatoes',
      price: '$18.99',
    },
    {
      title: 'Chicken Parmesan',
      price: '$15.99',
    },
    {
      title: 'Cheese Ravioli',
      price: '$14.99',
    },
    {
      title: 'Spaghetti with Meatballs',
      price: '$13.99',
    },
    {
      title: 'Rigatoni with Meatballs',
      price: '$13.99',
    },
    {
      title: 'Chicken Alfredo',
      price: '$21.99',
    },
    {
      title: 'Shrimp Alfredo',
      price: '$24.99',
    },
    {
      title: 'Shrimp Scampi',
      description:
        '6 shrimp served with a white wine and butter garlic sauce topped with diced tomatoes and served over linguini',
      price: '$20.99',
    },
  ],
};

export const seafoodSection: MenuSection = {
  title: 'Seafood Entrees',
  description:
    'Entrées include tossed salad, one side dish and bread & butter. $1 extra for ravioli',
  items: [
    {
      title: 'Fresh Tilapia - 8oz',
      price: '$14.99',
    },
    {
      title: 'Broiled Canadian White Fish - 12oz',
      price: '$16.99',
    },
    {
      title: 'Fried Canadian White Fish - 12oz',
      price: '$16.99',
    },
    {
      title: 'Yellow Fin Tuna Steak - 8oz',
      price: '$18.99',
    },
    {
      title: 'Norwegian Salmon - 8oz',
      price: '$18.99',
    },
    {
      title: 'Jumbo Deep Fried Shrimp - 6ct',
      price: '$16.99',
    },
    {
      title: 'Surf & Turf',
      description: `Abbey's perfectly aged filet mignon (10 oz), and succulent South African cold water lobster tail (9 oz)`,
      price: 'Market',
    },
    {
      title: `Joey's Jumbo Lobster Tail`,
      description: `Succulent South African cold water lobster tail`,
      price: 'Market',
    },
  ],
};

export const sandwichSection: MenuSection = {
  title: 'Sandwiches',
  description: `Choose a sandwich and any 2 of the following: french fries · onion rings · baked potato · cole slaw · cottage cheese · apple sauce · vegetable of the day · mini tossed salad`,
  items: [
    {
      description: '',
      title: 'Grilled Cheese',
      price: '$8.99',
    },
    {
      description: '',
      title: 'Meatball',
      price: '$10.99',
    },
    {
      description: '',
      title: 'Hot Sausage - 12oz',
      price: '$11.99',
    },
    {
      description: '',
      title: 'Hot Ham  - 10oz',
      price: '$11.99',
    },
    {
      description: '',
      title: 'Grilled Chicken',
      price: '$11.99',
    },

    {
      description: '',
      title: 'Hamburger - 12oz',
      price: '$11.99',
    },

    {
      description: '',
      title: 'Cheeseburger - 12oz',
      price: '$12.49',
    },

    {
      description: '',
      title: 'Fried Fish - 8oz',
      price: '$11.99',
    },
  ],
};

export const saladSection: MenuSection = {
  title: 'Salads',
  description: `House · Honey French · Ranch · Sweet & Sour · Thousand Island · Hot Bacon · Honey Mustard · Fat Free Italian · Fat Free Raspberry · House blue cheese ($.50)`,
  items: [
    {
      title: 'Spinach Salad',
      price: '$9.99',
    },
    {
      title: 'Chicken Salad',
      price: '$12.99',
    },
    {
      title: 'Steak Salad',
      price: '$13.99',
    },
    {
      title: 'Seafood Salad',
      price: '$14.99',
    },
    {
      title: 'Grilled Salmon Salad',
      price: '$13.99',
    },
    {
      title: 'Grilled Tuna Steak Salad',
      price: '$13.99',
    },
    {
      title: 'Mediterranean Salad',
      price: '$7.99',
    },
    {
      title: 'Tomato Salad',
      price: '$11.99',
    },
    {
      title: 'Caesar Salad',
      price: '$7.99',
    },
    {
      title: 'Chef Salad',
      price: '$14.99',
    },
    {
      title: 'Tossed Salad',
      price: '$3.99',
    },
  ],
  footer: ['Add cheese for an additional $.75', 'Extra dressing $.15'],
};

export const beveragesSection: MenuSection = {
  title: 'Beverages',
  items: [
    {
      price: '$1.99',
      title: 'Coffee',
    },
    {
      price: '$1.99',
      title: 'Milk',
    },
    {
      price: '$2.50',
      title: 'Fresh Brewed Iced Tea',
      description: 'Free refills',
    },
    {
      price: '$2.50',
      title: 'Fresh Brewed Sweet Tea',
      description: 'Free refills',
    },
    {
      description:
        'Pepsi, Diet Pepsi, Sierra Mist, Dr. Pepper, Mountain Dew, Pink Lemonade and Mug Root Beer',
      price: '$2.50',
      title: 'Fountain Soda',
    },
    {
      price: '$2.50',
      title: 'Lemon Lime Gatorade',
      description: 'Free refills',
    },
    {
      description:
        'Diet Caffeine Free Pepsi, Caffeine Free Pepsi, Ginger Ale and Diet 7up',
      price: '$2.25',
      title: 'Can Soda',
    },
  ],
};
