import { MDCRipple } from '@material/ripple';
import { useEffect, useRef } from 'react';

export type IconButtonProps = {
  icon: string;
  onClick: () => void;
};

export function IconButton(props: IconButtonProps) {
  const buttonRef = useRef<HTMLButtonElement | any>(null);

  useEffect(() => {
    const iconButtonRipple = new MDCRipple(buttonRef.current!);
    iconButtonRipple.unbounded = true;

    return () => iconButtonRipple.destroy();
  }, []);

  function InnerContent(props: IconButtonProps) {
    return (
      <>
        <div className='mdc-icon-button__ripple'></div>
        {props.icon}
        <div className='mdc-icon-button__touch'></div>
      </>
    );
  }

  function Button(props: IconButtonProps) {
    return (
      <button
        ref={buttonRef}
        className='mdc-icon-button mdc-icon-button--touch material-icons'
        onClick={props.onClick}>
        <InnerContent {...props} />
      </button>
    );
  }

  return (
    <div className='mdc-touch-target-wrapper'>
      <Button {...props} />
    </div>
  );
}
