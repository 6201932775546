import { useMemo, useReducer } from 'react';
import { Route } from '../router/types/Route';
import { GlobalState } from '../types/GlobalState';
import { MenuDisplayStatus, NavigationMenu } from '../types/NavigationMenu';
import { initState } from './initState';

export type Store = ReturnType<typeof useStore>;

export enum ActionType {
  setRoute = 'setRoute',
  presentNavigationMenu = 'presentNavigationMenu',
  dismissNavigationMenu = 'dismissNavigationMenu',
}

export type Action = {
  type: ActionType;
  payload: any;
  opts?: ReducerOptions;
};

export type ReducerOptions = {
  merge?: boolean;
};

export type ReducerFuction = (
  state: GlobalState,
  action: Action
) => GlobalState;

/** Root State Objects */
type Slice = Route & NavigationMenu;

export function useStore() {
  const [state, dispatch] = useReducer((state: GlobalState, action: Action) => {
    /** Options applied */
    const payload = action.payload;

    const reducer: Record<ActionType, (slice: Slice) => GlobalState> = {
      setRoute(slice) {
        return { ...state, route: slice };
      },
      presentNavigationMenu(slice) {
        return { ...state, navigationMenu: slice };
      },
      dismissNavigationMenu(slice) {
        return { ...state, navigationMenu: slice };
      },
    };

    const newState = reducer[action.type](payload);

    // if (process.env.NODE_ENV === 'development') {
    //   console.group('EVENT');
    //   console.log('>>> TYPE', action.type);
    //   console.log('>>> PAYLOAD', action.payload);
    //   console.log('>>> STATE', state);
    //   console.log('>>> NEW_STATE', newState);
    //   console.groupEnd();
    // }

    return reducer[action.type](payload);
  }, initState);

  const setters = useMemo(() => {
    return {
      setRoute(payload: Partial<Route>) {
        dispatch({ type: ActionType.setRoute, payload });
      },
      presentNavigationMenu(payload: {
        displayStatus:
          | MenuDisplayStatus.presenting
          | MenuDisplayStatus.presented;
      }) {
        dispatch({ type: ActionType.presentNavigationMenu, payload });
      },
      dismissNavigationMenu(payload: {
        displayStatus:
          | MenuDisplayStatus.dismissing
          | MenuDisplayStatus.dismissed;
      }) {
        dispatch({ type: ActionType.dismissNavigationMenu, payload });
      },
    } as const;
  }, [dispatch]);

  const getters = useMemo(() => {
    return {
      navigationMenu: {
        ...state.navigationMenu,
        presented:
          state.navigationMenu.displayStatus !== MenuDisplayStatus.dismissed,
      },
      productMenu: {
        menuId: state.route.segments.at(1)?.pageId,
      },
    } as const;
  }, [state.navigationMenu, state.route.segments]);

  return { state, dispatch, setters, getters };
}
