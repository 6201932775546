// import { MenuItem } from '../../types/MenuItem';
import Style from './MenuItem.module.scss';

import { MenuItem as _MenuItem } from '../../../types/MenuItem';
import { MenuPriceAlt } from '../../../types/MenuPriceAlt';

export type MenuItemProps = {} & _MenuItem;

export function MenuItemComp(props: MenuItemProps) {
  return (
    <div className={Style.root}>
      <div className={Style.content}>
        <div className={Style.titleDesc}>
          <div className={'mdc-typography--body1'}>{props.title}</div>
          {props.description && (
            <div style={{ opacity: '0.6' }} className={'mdc-typography--body2'}>
              {props.description}
            </div>
          )}
        </div>
        {props.price && (
          <div className={'mdc-typography--body1'}>{props.price}</div>
        )}
      </div>
      {props.priceAlts && (
        <div>
          {props.priceAlts.map((p, i) => (
            <AltItem key={i} {...p} />
          ))}
        </div>
      )}
    </div>
  );
}

function AltItem(props: MenuPriceAlt) {
  return (
    <div className={Style.altPrice}>
      <div className={'mdc-typography--body1'}>{props.title}</div>
      <div className={'mdc-typography--body1'}>{props.price}</div>
    </div>
  );
}
