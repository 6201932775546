import React, { CSSProperties, useEffect, useRef } from 'react';
import { MDCRipple } from '@material/ripple';
import { Icon } from '../icon';

export interface ButtonProps {
  text: string;
  icon?: string;
  href?: string;
  onClick?: (e: React.MouseEvent) => void;
  type?: string;
  style?: CSSProperties;
}

export function Button(props: ButtonProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { text, onClick } = props;
  const eventHand = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (onClick) {
      onClick(e);
    } else if (props.href) {
      window.location.href = props.href;
    }
  };

  useEffect(() => {
    const controller = new MDCRipple(buttonRef.current!);
    return () => controller.destroy();
  }, []);

  return (
    <button
      ref={buttonRef}
      style={props.style}
      className='mdc-button  mdc-button--icon-leading'
      onClick={eventHand}>
      <span className='mdc-button__ripple'></span>

      {props.icon && <Icon name={props.icon} />}

      <span className='mdc-button__label'>{text}</span>

      <span className='mdc-button__touch'></span>
    </button>
  );
}
